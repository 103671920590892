import image_1 from '../images/photos/apartmany_humenne_1.jpg'
import image_2 from '../images/photos/apartmany_humenne_2.jpg'
import image_3 from '../images/photos/apartmany_humenne_3.jpg'
import image_4 from '../images/photos/apartmany_humenne_4.jpg'
import image_5 from '../images/photos/apartmany_humenne_5.jpg'
import image_6 from '../images/photos/apartmany_humenne_6.jpg'
import image_7 from '../images/photos/apartmany_humenne_7.jpg'
import image_8 from '../images/photos/apartmany_humenne_8.jpg'
import image_9 from '../images/photos/apartmany_humenne_9.jpg'
import image_10 from '../images/photos/apartmany_humenne_10.jpg'
import image_11 from '../images/photos/apartmany_humenne_11.jpg'
import image_12 from '../images/photos/apartmany_humenne_12.jpg'
import image_13 from '../images/photos/apartmany_humenne_13.jpg'
import image_14 from '../images/photos/apartmany_humenne_14.jpg'
import image_15 from '../images/photos/apartmany_humenne_15.jpg'
import image_16 from '../images/photos/apartmany_humenne_16.jpg'
import image_17 from '../images/photos/apartmany_humenne_17.jpg'
import image_18 from '../images/photos/apartmany_humenne_18.jpg'
import image_19 from '../images/photos/apartmany_humenne_19.jpg'
import image_20 from '../images/photos/apartmany_humenne_20.jpg'
import image_21 from '../images/photos/apartmany_humenne_21.jpg'
import image_22 from '../images/photos/apartmany_humenne_22.jpg'
import image_23 from '../images/photos/apartmany_humenne_23.jpg'
import image_24 from '../images/photos/apartmany_humenne_24.jpg'
import image_25 from '../images/photos/apartmany_humenne_25.jpg'
import image_26 from '../images/photos/apartmany_humenne_26.jpg'
import image_27 from '../images/photos/apartmany_humenne_27.jpg'
import image_28 from '../images/photos/apartmany_humenne_28.jpg'
import image_29 from '../images/photos/apartmany_humenne_29.jpg'
import image_30 from '../images/photos/apartmany_humenne_30.jpg'
import image_31 from '../images/photos/apartmany_humenne_31.jpg'
import image_32 from '../images/photos/apartmany_humenne_32.jpg'
import image_33 from '../images/photos/apartmany_humenne_33.jpg'
import image_34 from '../images/photos/apartmany_humenne_34.jpg'
import image_35 from '../images/photos/apartmany_humenne_35.jpg'

export type ImageMapItem = {
  index: number
  url: string
  description: string
}

const JEDNOSPALNOVY = 'Jednospálňový apartmán /max. 3 osoby/'
const VIACSPALNOVY_4 = 'Viacspálňový apartmán /max. 4 osoby/'
const VIACSPALNOVY_6 = 'Viacspálňový apartmán s klimatizáciou /max. 6 osôb/'
const PODKROVNY = 'Podkrovný apartmán s klimatizáciou /max. 4 osoby/'

export const imageMap: Record<string, ImageMapItem> = {
  image_1: {
    index: 1,
    url: image_1,
    description: JEDNOSPALNOVY,
  },
  image_2: {
    index: 2,
    url: image_2,
    description: JEDNOSPALNOVY,
  },
  image_3: {
    index: 3,
    url: image_3,
    description: JEDNOSPALNOVY,
  },
  image_4: {
    index: 4,
    url: image_4,
    description: JEDNOSPALNOVY,
  },
  image_5: {
    index: 5,
    url: image_5,
    description: JEDNOSPALNOVY,
  },
  image_6: {
    index: 6,
    url: image_6,
    description: VIACSPALNOVY_4,
  },
  image_7: {
    index: 7,
    url: image_7,
    description: VIACSPALNOVY_4,
  },
  image_8: {
    index: 8,
    url: image_8,
    description: VIACSPALNOVY_4,
  },
  image_9: {
    index: 9,
    url: image_9,
    description: VIACSPALNOVY_4,
  },
  image_10: {
    index: 10,
    url: image_10,
    description: VIACSPALNOVY_4,
  },
  image_11: {
    index: 11,
    url: image_11,
    description: VIACSPALNOVY_4,
  },
  image_12: {
    index: 12,
    url: image_12,
    description: VIACSPALNOVY_4,
  },
  image_13: {
    index: 13,
    url: image_13,
    description: VIACSPALNOVY_6,
  },
  image_14: {
    index: 14,
    url: image_14,
    description: VIACSPALNOVY_6,
  },
  image_15: {
    index: 15,
    url: image_15,
    description: VIACSPALNOVY_6,
  },
  image_16: {
    index: 16,
    url: image_16,
    description: VIACSPALNOVY_6,
  },
  image_17: {
    index: 17,
    url: image_17,
    description: VIACSPALNOVY_6,
  },
  image_18: {
    index: 18,
    url: image_18,
    description: VIACSPALNOVY_6,
  },
  image_19: {
    index: 19,
    url: image_19,
    description: VIACSPALNOVY_6,
  },
  image_20: {
    index: 20,
    url: image_20,
    description: VIACSPALNOVY_6,
  },
  image_21: {
    index: 21,
    url: image_21,
    description: VIACSPALNOVY_6,
  },
  image_22: {
    index: 22,
    url: image_22,
    description: VIACSPALNOVY_6,
  },
  image_23: {
    index: 23,
    url: image_23,
    description: VIACSPALNOVY_6,
  },
  image_24: {
    index: 24,
    url: image_24,
    description: PODKROVNY,
  },
  image_25: {
    index: 25,
    url: image_25,
    description: PODKROVNY,
  },
  image_26: {
    index: 26,
    url: image_26,
    description: PODKROVNY,
  },
  image_27: {
    index: 27,
    url: image_27,
    description: PODKROVNY,
  },
  image_28: {
    index: 28,
    url: image_28,
    description: PODKROVNY,
  },
  image_29: {
    index: 29,
    url: image_29,
    description: PODKROVNY,
  },
  image_30: {
    index: 30,
    url: image_30,
    description: 'Úschovňa batožiny/bicyklov/kočiarov',
  },
  image_31: {
    index: 31,
    url: image_31,
    description: 'Exteriér /od hlavnej cesty/',
  },
  image_32: {
    index: 32,
    url: image_32,
    description: 'Vstup do apartmánov /od parkoviska/',
  },
  image_33: {
    index: 33,
    url: image_33,
    description: 'Vstup do apartmánov /od parkoviska/',
  },
  image_34: {
    index: 34,
    url: image_34,
    description: 'Exteriér /od hlavnej cesty/',
  },
  image_35: {
    index: 35,
    url: image_35,
    description: 'Vstup do apartmánov /od parkoviska/',
  },
}

export const totalImages = Object.keys(imageMap).length
