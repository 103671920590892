import React from 'react'

import arrowDown from '../images/arrowDown32.png'
import {
  EMAIL,
  PHONE,
  PHONE_HREF,
  EMAIL_HREF,
  OFFSET_TOP,
  Sections,
} from '../utils/constants'
import { scrollToY } from '../utils/helpers'

const LandingContent: React.FC = () => {
  return (
    <React.Fragment>
      <div id="landingContainer">
        <h1 id="heading">Apartmány Humenné</h1>
        <h3 id="subHeading">
          Kvalitné ubytovanie v priestranných apartmánoch
          <br />
          priamo v centre mesta
        </h3>
      </div>
      <div id="reservation">
        <p id="priceLandingPage">Rezervácie na:</p>
        <div className="reservation-container">
          <p id="phoneLandingPage">
            <a href={PHONE_HREF}>{PHONE}</a>
          </p>
          <p id="emailLandingPage">
            <a href={EMAIL_HREF}>{EMAIL}</a>
          </p>
        </div>
      </div>
    </React.Fragment>
  )
}

export const LandingPage: React.FC = () => {
  const onClickArrow = () => {
    const uvodSection = document.getElementById(Sections.uvod)

    if (uvodSection) {
      scrollToY({
        scrollTargetY: uvodSection.offsetTop - OFFSET_TOP,
      })
    }
  }

  return (
    <header>
      <div className="mainHeaderSmallBg">
        <LandingContent />
      </div>

      <div className="mainHeaderBigBg">
        <LandingContent />
      </div>

      <div id="arrowDown" onClick={onClickArrow}>
        <img src={arrowDown} alt="Sipka dole" />
      </div>
    </header>
  )
}
