import React from 'react'
import Helmet from 'react-helmet'

const title = `Apartmány Humenné - Kvalitné ubytovanie v centre mesta`
const metaDescription =
  'Kvalitné a cenovo dostupné ubytovanie v nových, plne vybavených apartmánoch v centre Humenného. V bezprostrednej blízkosti apartmánov sa nachádza pešia zóna s renesančným kaštieľom, reštaurácie, lahôdky so širokým raňajkovým a obedovým menu a obchod s potravinami. Tešíme sa na Vašu návštevu.'
const language = 'sk'
const author = 'Peter Sciranka'
const metadataOgDescription =
  'Kvalitné a cenovo dostupné ubytovanie v nových, plne vybavených apartmánoch v centre Humenného.'

export const SEO: React.FC = () => {
  return (
    <Helmet
      htmlAttributes={{
        lang: language,
      }}
      title={title}
      titleTemplate={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metadataOgDescription,
        },
      ].concat()}
    >
      <script
        type="text/javascript"
        src="https://maps.googleapis.com/maps/api/js?callback=initMap&key=AIzaSyBZ_vJVQ2M7yxcgSjkT2HHuZAuldJ_Wr1g&language=sk"
        async
        defer
      />
      <script type="text/javascript">
        {`function initMap() {
          var e = { zoom: 17, center: { lat: 48.933567, lng: 21.907837 } }
          var map = new google.maps.Map(document.getElementById('map'), e)

          new google.maps.Marker({
            position: { lat: 48.933567, lng: 21.907837 },
            map: map,
          })
        }`}
      </script>
      <script
        id="Cookiebot"
        src="https://consent.cookiebot.com/uc.js"
        data-cbid="5c417d24-c278-40b7-9df7-4bbafeb70361"
        type="text/javascript"
        async
      ></script>
    </Helmet>
  )
}
