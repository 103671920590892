import React, { useCallback, useEffect, useState } from 'react'
import { VscChevronLeft, VscChevronRight, VscClose } from 'react-icons/vsc'

import { GaleriaSwiper } from './GaleriaSwiper'
import { Photo } from './Photo'
import { imageMap, totalImages } from './galeriaList'

const getNextImageIndex = (currentIndex: number) => {
  if (currentIndex === totalImages) {
    return 1
  }
  return currentIndex + 1
}

const getPreviousImageIndex = (currentIndex: number) => {
  if (currentIndex === 1) {
    return totalImages
  }
  return currentIndex - 1
}

interface Props {
  isVisible: boolean
  onHideModal: () => void
}

const FIRST_IMAGE = imageMap['image_1']

export const GaleriaModal: React.FC<Props> = ({ onHideModal, isVisible }) => {
  const [currentImage, setCurrentImage] = useState(FIRST_IMAGE)

  const onPressNextImage = useCallback(() => {
    const currentImageIndex = currentImage.index
    const nextImageIndex = getNextImageIndex(currentImageIndex)
    setCurrentImage(imageMap[`image_${nextImageIndex}`])
  }, [currentImage.index])

  const onPressPreviousImage = useCallback(() => {
    const currentImageIndex = currentImage.index
    const previousImageIndex = getPreviousImageIndex(currentImageIndex)
    setCurrentImage(imageMap[`image_${previousImageIndex}`])
  }, [currentImage.index])

  const hideIfVisible = useCallback(() => {
    if (isVisible) {
      onHideModal()
    }
  }, [isVisible, onHideModal])

  useEffect(() => {
    window.addEventListener('popstate', hideIfVisible)
    window.addEventListener('onhashchange', hideIfVisible)

    return () => {
      window.removeEventListener('popstate', hideIfVisible)
      window.removeEventListener('onhashchange', hideIfVisible)
    }
  }, [hideIfVisible])

  const onHandleEvent = useCallback(
    (event: any) => {
      if (event?.code === 'ArrowRight') {
        onPressNextImage()
      }
      if (event?.code === 'ArrowLeft') {
        onPressPreviousImage()
      }
      if (event?.code === 'Escape') {
        onHideModal()
      }
    },
    [onHideModal, onPressNextImage, onPressPreviousImage]
  )

  useEffect(() => {
    if (isVisible) {
      document.addEventListener('keydown', onHandleEvent)
    } else {
      document.removeEventListener('keydown', onHandleEvent)
    }

    return () => {
      document.removeEventListener('keydown', onHandleEvent)
    }
  }, [isVisible, onHandleEvent])

  useEffect(() => {
    if (!isVisible) {
      setCurrentImage(FIRST_IMAGE)
    }
  }, [isVisible])

  if (isVisible) {
    return (
      <React.Fragment>
        <div className="galleryModal">
          <span id="crossCloseGalleryModal" onClick={onHideModal}>
            <VscClose />
          </span>

          {/* GALLERY SMALL SCREENS */}
          <div className="galleryForSmallScreens">
            <div className="galleryContent">
              <div className="gallerySwiperContainer">
                <GaleriaSwiper />
              </div>
            </div>
          </div>

          {/* GALLERY BIG SCREENS */}
          <div className="galleryForBigScreens">
            <div id="modalGalleryHeadingContainer">
              <div id="modalGalleryHeading">
                <p id="photosCounting">{`${currentImage.index} / ${totalImages}`}</p>
                <p id="modalPhotoDescription">{currentImage.description}</p>
              </div>
            </div>
            <div className="galleryContent">
              <div id="actualPhotoContainer">
                <Photo src={currentImage.url} alt={currentImage.description} />
              </div>

              <div id="galleryNav">
                <div className="galleryNavContainer galleryNavLeftContainer">
                  <div
                    onClick={onPressPreviousImage}
                    className="galleryChevronContainer"
                  >
                    <VscChevronLeft />
                  </div>
                </div>

                <div className="galleryNavContainer galleryNavRightContainer">
                  <div
                    onClick={onPressNextImage}
                    className="galleryChevronContainer"
                  >
                    <VscChevronRight />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

  return null
}
