import React from 'react'

import { Section } from './Section'
import { Sections } from '../utils/constants'

export const Cennik: React.FC = () => {
  return (
    <Section
      id={Sections.cennik}
      heading="Cenník"
      subHeading="cena za 1 apartmán / 1 noc s DPH"
    >
      {/* <!-- JEDNOSPÁLŇOVÝ BYT --> */}
      <div className="bytContainer">
        <div className="cennikByt">
          <div className="cennikHead">JEDNOSPÁLŇOVÝ (3 lôžka)</div>
          <div className="cenaContainer">
            <div className="polozkaCena">
              <div className="pocetOsob">1 - 2 osoby</div>
              <div className="cena">
                <span className="akcnaCena">50,00 &euro;</span>&nbsp;
              </div>
            </div>
            <div className="polozkaCena">
              <div className="pocetOsob">3 osoby</div>
              <div className="cena">
                <span className="akcnaCena">55,00 &euro;</span>&nbsp;
              </div>
            </div>
          </div>
        </div>
        <div className="vybavenie">
          <p>
            veľká 3-lôžková izba s TV a sedacou súpravou, kuchyňa, kúpeľňa
            (sprchový kút, WC), klimatizácia
          </p>
        </div>
      </div>

      {/* <!-- VIACSPÁLŇOVÝ BYT --> */}
      <div className="bytContainer">
        <div className="cennikByt">
          <div className="cennikHead">VIACSPÁLŇOVÝ (5 + 1 lôžok)</div>
          <div className="cenaContainer">
            <div className="polozkaCena">
              <div className="pocetOsob">1 - 2 osoby</div>
              <div className="cena">
                <span className="akcnaCena">50,00 &euro;</span>&nbsp;
              </div>
            </div>
            <div className="polozkaCena">
              <div className="pocetOsob">3 osoby</div>
              <div className="cena">
                <span className="akcnaCena">55,00 &euro;</span>&nbsp;
              </div>
            </div>
            <div className="polozkaCena">
              <div className="pocetOsob">4 osoby</div>
              <div className="cena">
                <span className="akcnaCena">60,00 &euro;</span>&nbsp;
              </div>
            </div>
            <div className="polozkaCena">
              <div className="pocetOsob">5 osôb</div>
              <div className="cena">
                <span className="akcnaCena">65,00 &euro;</span>&nbsp;
              </div>
            </div>
            <div className="polozkaCena">
              <div className="pocetOsob">6 osôb</div>
              <div className="cena">
                <span className="akcnaCena">70,00 &euro;</span>&nbsp;
              </div>
            </div>
          </div>
        </div>
        <div className="vybavenie">
          <p>
            2x 2-lôžková izba s TV, 1x 1-lôžková izba, prístelka, kuchyňa,
            kúpeľňa (vaňa, WC), klimatizácia podľa dostupnosti
          </p>
        </div>
      </div>

      {/* <!-- PODKROVNÝ BYT --> */}
      <div className="bytContainer">
        <div className="cennikByt">
          <div className="cennikHead">PODKROVNÝ (4 lôžka)</div>
          <div className="cenaContainer">
            <div className="polozkaCena">
              <div className="pocetOsob">1 - 2 osoby</div>
              <div className="cena">
                <span className="akcnaCena">50,00 &euro;</span>&nbsp;
              </div>
            </div>
            <div className="polozkaCena">
              <div className="pocetOsob">3 osoby</div>
              <div className="cena">
                <span className="akcnaCena">55,00 &euro;</span>&nbsp;
              </div>
            </div>
            <div className="polozkaCena">
              <div className="pocetOsob">4 osoby</div>
              <div className="cena">
                <span className="akcnaCena">60,00 &euro;</span>&nbsp;
              </div>
            </div>
          </div>
        </div>
        <div className="vybavenie">
          <p>
            2x 2-lôžková izba s TV, prístelka, hala s TV a sedacou súpravou,
            kuchyňa, kúpeľňa (vaňa, WC), klimatizácia
          </p>
        </div>
      </div>

      {/* DODATOK */}
      <div id="cenaDodatok">
        <p>
          Domáce zviera: <span className="akcnaCena">10,00 &euro;</span>
          <span className="bold">/ 1 noc.</span>
        </p>
        <p>
          Klimatizácia v apartmáne:{' '}
          <span className="akcnaCena">5,00 &euro;</span>
          <span className="bold">/ 1 noc.</span>
        </p>
        <p>
          Turistický poplatok:{' '}
          <span className="bold">
            1,50 &euro; osoba / noc (nie je zahrnutý v cene apartmánu)
          </span>
          .
        </p>
        <p>
          Platba:{' '}
          <span className="bold">HOTOVOSŤ / PLATOBNÁ KARTA / FAKTÚRA.</span>
        </p>
        <p>
          Príchod: <span className="bold">od 14:00 hod. do 20:00 hod.</span> -
          nutné dohodnúť telefonicky.
        </p>
        <p>
          Odchod: <span className="bold">do 10:00 hod.</span>, prípadne dohodou.
        </p>
      </div>
    </Section>
  )
}
