import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useHasMobileDimensions } from '../utils/helpers'

import { imageMap, totalImages } from './galeriaList'
import { Photo } from './Photo'

interface Props {
  src: string
  alt: string
  description: string
}

const SliderPhoto: React.FC<Props> = ({ alt, src, description }) => {
  const { windowHeight, windowWidth } = useHasMobileDimensions()
  const height = windowHeight - 150

  return (
    <div className="gallerySwiperPhotoContainer">
      <div style={{ textAlign: 'center', padding: '0 24px' }}>
        {description}
      </div>
      <div
        style={{
          height: height,
          width: windowWidth,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
        }}
      >
        <Photo src={src} alt={alt} />
      </div>
    </div>
  )
}

export const GaleriaSwiper: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(1)

  const onSlide = (swiper: any) => {
    console.log(swiper)

    setCurrentIndex(swiper.activeIndex + 1)
  }

  return (
    <React.Fragment>
      <div className="swiperCounter">{`${currentIndex}/${totalImages}`}</div>

      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        onSlideChange={(swiper) => onSlide(swiper)}
        style={{ height: '100%', width: '100%' }}
      >
        {Object.values(imageMap).map((image) => {
          return (
            <SwiperSlide key={image.index}>
              <SliderPhoto
                src={image.url}
                alt={image.description}
                description={image.description}
              />
            </SwiperSlide>
          )
        })}
      </Swiper>
    </React.Fragment>
  )
}
