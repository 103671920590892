import React from 'react'

import { Section } from './Section'

import iconWiFi from '../images/icons/iconWiFi.png'
import iconBed from '../images/icons/iconBed.png'
import iconTV from '../images/icons/iconTV.png'
import iconBath from '../images/icons/iconBath.png'
import iconFridge from '../images/icons/iconFridge.png'
import noSmoking from '../images/icons/noSmoking.png'
import { Sections } from '../utils/constants'

export const Uvod: React.FC = () => {
  return (
    <Section id={Sections.uvod} heading="Apartmány v centre mesta!">
      <p>
        Celoročné nonstop ubytovanie v priestranných apartmánoch s kapacitou 50
        lôžok.
        <br />
        Každý apartmán disponuje vlastnou kúpeľňou (sprchový kút / vaňa), WiFi
        pripojením, TV, plne vybavenou kuchyňou a je zariadený s dôrazom na
        pohodlie a kvalitný odpočinok.
        <br />
        V celom objekte platí prísny zákaz fajčenia.
      </p>
      <div className="iconContainer">
        <img src={iconWiFi} alt="WiFi ikona" />
        <img src={iconBed} alt="posteľ ikona" />
        <img src={iconTV} alt="tv ikona" />
        <img src={iconBath} alt="kúpeľňa ikona" />
        <img src={iconFridge} alt="chladnička ikona" />
        <img src={noSmoking} alt="zákaz fajčenia" />
      </div>
    </Section>
  )
}
