export const EMAIL = 'info@apartmanyhumenne.sk'
export const EMAIL_HREF = 'mailto:info@apartmanyhumenne.sk'
export const PHONE = '+421 918 405 730'
export const PHONE_HREF = 'tel:+421918405730'

export const OFFSET_TOP = 50

export enum Sections {
  uvod = 'uvod',
  vybavenie = 'vybavenie',
  cennik = 'cennik',
  galeria = 'galeria',
  kontakt = 'kontakt',
}
