import { useEffect, useState } from 'react'

export enum Easing {
  easeOutSine = 'easeOutSine',
  easeInOutSine = 'easeInOutSine',
  easeInOutQuint = 'easeInOutQuint',
}

// easing equations from https://github.com/danro/easing-js/blob/master/easing.js
const easingEquations = {
  [Easing.easeOutSine]: (pos: number) => {
    return Math.sin(pos * (Math.PI / 2))
  },
  [Easing.easeInOutSine]: (pos: number) => {
    return -0.5 * (Math.cos(Math.PI * pos) - 1)
  },
  [Easing.easeInOutQuint]: (pos: number) => {
    if ((pos /= 0.5) < 1) {
      return 0.5 * Math.pow(pos, 5)
    }
    return 0.5 * (Math.pow(pos - 2, 5) + 2)
  },
}

interface ScrollToY {
  scrollTargetY: number // scrollTargetY: the target scrollY property of the window
  speed?: number // speed: time in pixels per second
  easing?: Easing // easing: easing equation to use
}

export const scrollToY = ({
  scrollTargetY = 0,
  speed = 100,
  easing = Easing.easeInOutQuint,
}: ScrollToY): void => {
  const scrollY = window.scrollY || document.documentElement.scrollTop
  let currentTime = 0

  // min time .1, max time .8 seconds
  const time = Math.max(
    0.1,
    Math.min(Math.abs(scrollY - scrollTargetY) / speed, 0.8)
  )

  // add animation loop
  const tick = () => {
    currentTime += 1 / 60

    const p = currentTime / time
    const t = easingEquations[easing](p)

    if (p < 1) {
      window.requestAnimationFrame(tick)

      window.scrollTo(0, scrollY + (scrollTargetY - scrollY) * t)
    } else {
      window.scrollTo(0, scrollTargetY)
    }
  }
  // call it once to get started
  tick()
}

type MobileDimensionHook = {
  windowHeight: number
  windowWidth: number
}

// const IS_BROWSER = typeof window !== `undefined`

export const useHasMobileDimensions = (): MobileDimensionHook => {
  const [windowWidth, setWindowWidth] = useState<number>(0)
  const [windowHeight, setWindowHeight] = useState<number>(0)

  const onWindowResize = () => {
    setWindowWidth(window.innerWidth)
    setWindowHeight(window.innerHeight)
  }

  useEffect(() => {
    onWindowResize()

    window.addEventListener('resize', () => {
      onWindowResize()
    })

    return () => {
      window.removeEventListener('resize', onWindowResize)
    }
  }, [])

  return { windowHeight, windowWidth }
}
