import React from 'react'

import { Section } from './Section'

import doska from '../images/icons/doska.png'
import susic from '../images/icons/susic.png'
import postielka from '../images/icons/postielka.png'
import parkovanie from '../images/icons/parkovanie.png'
import batozina from '../images/icons/batozina.png'
import bicykel from '../images/icons/bicykel.png'
import kocik from '../images/icons/kocik.png'
import { Sections } from '../utils/constants'

export const Vybavenie: React.FC = () => {
  return (
    <Section
      id={Sections.vybavenie}
      heading="Vybavenie apartmánov"
      grayBackground={true}
    >
      <ul className="vybavenie-zoznam">
        <li>
          <span className="bold">televízor</span> – káblový príjem TV
        </li>
        <li>
          <span className="bold">internet</span> – WIFI
        </li>
        <li>
          <span className="bold">izby</span> – postele/prístelky, skrine, nočné
          stolíky, kreslá/stoličky, sedacie súpravy
        </li>
        <li>
          <span className="bold">kúpeľňa</span> – sprchový kút/vaňa, WC,
          umývadlo
        </li>
        <li>
          <span className="bold">kuchyňa</span> – plne vybavená kuchynská linka,
          jedálenský stôl, chladnička, elektrický sporák/indukčná platňa,
          mikrovlnná rúra, rýchlovarná kanvica
        </li>
      </ul>
      <h3 className="vybavenie-subheading">Na recepcii:</h3>
      <ul className="vybavenie-zoznam">
        <li>žehlička a žehliaca doska</li>
        <li>fén na vlasy</li>
        <li>detská postieľka</li>
      </ul>
      <h3 className="vybavenie-subheading">K dispozícii je:</h3>
      <ul className="vybavenie-zoznam">
        <li>bezplatné parkovanie chránené kamerovým systémom</li>
        <li>úschovňa batožiny/bicyklov/kočíkov</li>
      </ul>
      <div className="iconContainer text-center">
        <img src={doska} alt="žehliaca doska ikona" />
        <img src={susic} alt="sušič na vlasy ikona" />
        <img src={postielka} alt="detská postieľka ikona" />
        <img src={parkovanie} alt="parkovanie ikona" />
        <img src={batozina} alt="batožina ikona" />
        <img src={bicykel} alt="bicykel ikona" />
        <img src={kocik} alt="kočík ikona" />
      </div>
    </Section>
  )
}
