import React from 'react'

import { Section } from './Section'
import {
  PHONE,
  PHONE_HREF,
  EMAIL,
  Sections,
  EMAIL_HREF,
} from '../utils/constants'

export const Kontakt: React.FC = () => {
  return (
    <Section id={Sections.kontakt} heading="Kontakt">
      <div id="kontaktContainer">
        <div id="kontaktFormular" className="adressItem">
          <p className="bold adressItemHead">REZERVÁCIE:</p>
          <p>
            <span className="kontakt-item-head">MOBIL:</span>
            <span className="bold">
              <a href={PHONE_HREF} id="kontakt-tel">
                {PHONE}
              </a>
            </span>
          </p>
          <p>
            <span className="kontakt-item-head">EMAIL:</span>
            <span className="bold">
              <a href={EMAIL_HREF} id="kontakt-email">
                {EMAIL}
              </a>
            </span>
          </p>
        </div>
        <div id="kontaktAdresa" className="adressItem">
          <p className="bold adressItemHead">ADRESA:</p>
          <p id="actualAdress">
            Apartmány Humenné
            <br />
            Kukorelliho 1503/42
            <br />
            066 01 Humenné
          </p>
          <p id="odkazNaMapy">
            <a target="blank" href="https://goo.gl/maps/YCn1ggGxZiSmWBV67">
              Pozri v Google mapách
            </a>
          </p>
        </div>
      </div>
    </Section>
  )
}
