import React, { ReactNode } from 'react'

interface Props {
  id: string
  heading: string
  subHeading?: string
  grayBackground?: boolean
  children: ReactNode
}

export const Section: React.FC<Props> = ({
  id,
  heading,
  subHeading,
  grayBackground = false,
  children,
}) => {
  const className = !!grayBackground ? 'bg-gray' : ''

  return (
    <section id={id} className={className}>
      <div className="sectionContainer">
        <div className="sectionHeadingContainer">
          <h2 className="sectionHeading">{heading}</h2>
          <div className="sectionSubHeading">{subHeading}</div>
        </div>

        {children}
      </div>
    </section>
  )
}
