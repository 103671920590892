import React, { useEffect } from 'react'
import { Sections, OFFSET_TOP } from '../utils/constants'
import { scrollToY } from '../utils/helpers'

// SCROLL POSITION
const getScrollTop = (): number => {
  const supportPageOffset = window.pageXOffset !== undefined
  const isCSS1Compat = (document.compatMode || '') === 'CSS1Compat'
  const y = supportPageOffset
    ? window.pageYOffset
    : isCSS1Compat
    ? document.documentElement.scrollTop
    : document.body.scrollTop
  return y
}

const checkScrollForNavSize = () => {
  const navComponent = document.getElementById('myNav')

  if (navComponent && window.innerWidth > 414) {
    if (getScrollTop() > 50) {
      navComponent.style.fontSize = 18 + 'px'
    } else {
      navComponent.style.fontSize = 20 + 'px'
    }
  }
}

const onClickNav = (section: Sections) => {
  const _section = document.getElementById(section)

  if (_section) {
    scrollToY({ scrollTargetY: _section.offsetTop - OFFSET_TOP })
  }
}

export const Nav: React.FC = () => {
  useEffect(() => {
    window.addEventListener('scroll', checkScrollForNavSize)

    return () => {
      window.removeEventListener('scroll', checkScrollForNavSize)
    }
  }, [])

  const onClickUvod = () => {
    onClickNav(Sections.uvod)
  }

  const onClickApartmany = () => {
    onClickNav(Sections.vybavenie)
  }

  const onClickCennik = () => {
    onClickNav(Sections.cennik)
  }

  const onClickGaleria = () => {
    onClickNav(Sections.galeria)
  }

  const onClickKontakt = () => {
    onClickNav(Sections.kontakt)
  }

  return (
    <nav className="nav">
      <div id="myNav">
        <div className="navItem" onClick={onClickUvod}>
          <a>Úvod</a>
        </div>
        <div className="navItem navItemHideOnSmall" onClick={onClickApartmany}>
          <a>Apartmány</a>
        </div>
        <div className="navItem" onClick={onClickCennik}>
          <a>Cenník</a>
        </div>
        <div className="navItem" onClick={onClickGaleria}>
          <a>Galéria</a>
        </div>
        <div className="navItem" onClick={onClickKontakt}>
          <a>Kontakt</a>
        </div>
      </div>
    </nav>
  )
}
