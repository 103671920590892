import React, { useState } from 'react'

import { Section } from './Section'
import { GaleriaModal } from './GaleriaModal'

import small1 from '../images/photos-small/apartmany_humenne_small_1.jpg'
import small5 from '../images/photos-small/apartmany_humenne_small_5.jpg'
import small11 from '../images/photos-small/apartmany_humenne_small_11.jpg'
import small13 from '../images/photos-small/apartmany_humenne_small_13.jpg'
import small15 from '../images/photos-small/apartmany_humenne_small_15.jpg'
import small22 from '../images/photos-small/apartmany_humenne_small_22.jpg'
import small23 from '../images/photos-small/apartmany_humenne_small_23.jpg'
import small31 from '../images/photos-small/apartmany_humenne_small_31.jpg'
import { Sections } from '../utils/constants'

export const Galeria: React.FC = () => {
  const [isGalleryVisible, setIsGalleryVisible] = useState<boolean>(false)

  const onPressPhoto = () => {
    history.pushState('MODAL', '')

    setIsGalleryVisible(true)
    document.documentElement.style.overflowY = 'hidden'
  }

  const onHideModal = () => {
    setIsGalleryVisible(false)
    document.documentElement.style.overflowY = 'auto'
  }

  return (
    <React.Fragment>
      <Section id={Sections.galeria} heading="Galéria" grayBackground={true}>
        <p id="galeriaHelpText">
          Po kliknutí na fotku sa zobrazí celá galéria.
        </p>
        <div id="galeriaContainer">
          <div id="galeriaRow">
            <div className="galeriaColumn">
              <img
                className="photo"
                src={small1}
                alt="fotka-apartmany-humenné-2"
                id="photo_2"
                onClick={onPressPhoto}
              />
              <img
                className="photo"
                src={small11}
                alt="fotka-apartmany-humenné-18"
                id="photo_18"
                onClick={onPressPhoto}
              />
            </div>
            <div className="galeriaColumn">
              <img
                className="photo"
                src={small15}
                alt="fotka-apartmany-humenné-15"
                id="photo_15"
                onClick={onPressPhoto}
              />
              <img
                className="photo"
                src={small31}
                alt="fotka-apartmany-humenné-1"
                id="photo_1"
                onClick={onPressPhoto}
              />
            </div>
            <div className="galeriaColumn">
              <img
                className="photo"
                src={small13}
                alt="fotka-apartmany-humenné-10"
                id="photo_10"
                onClick={onPressPhoto}
              />
              <img
                className="photo"
                src={small5}
                alt="fotka-apartmany-humenné-16"
                id="photo_16"
                onClick={onPressPhoto}
              />
            </div>
            <div className="galeriaColumn">
              <img
                className="photo"
                src={small23}
                alt="fotka-apartmany-humenné-21"
                id="photo_21"
                onClick={onPressPhoto}
              />
              <img
                className="photo"
                src={small22}
                alt="fotka-apartmany-humenné-11"
                id="photo_11"
                onClick={onPressPhoto}
              />
            </div>
          </div>
        </div>
      </Section>

      <GaleriaModal isVisible={isGalleryVisible} onHideModal={onHideModal} />
    </React.Fragment>
  )
}
