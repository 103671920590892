import React, { useEffect, useState } from 'react'

import { Spinner } from './Spinner'

interface Props {
  src: string
  alt: string
}

export const Photo: React.FC<Props> = ({ src, alt }) => {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
  }, [src])

  const onLoadStart = () => {
    setIsLoading(true)
  }

  const onLoad = () => {
    setIsLoading(false)
  }

  return (
    <>
      <img
        className="imageGallery"
        src={src}
        alt={alt}
        onLoadStart={onLoadStart}
        onLoad={onLoad}
      />

      {isLoading && (
        <div id="loadingModal">
          <Spinner />
        </div>
      )}
    </>
  )
}
