import React from 'react'

import { SEO } from '../components/SEO'
import { Cennik } from '../components/Cennik'
import { LandingPage } from '../components/LandingPage'
import { Uvod } from '../components/Uvod'
import { Vybavenie } from '../components/Vybavenie'
import { Galeria } from '../components/Galeria'
import { Kontakt } from '../components/Kontakt'
import { Footer } from '../components/Footer'
import { Nav } from '../components/Nav'
import { GoogleMap } from '../components/Map'

export default function App(): any {
  return (
    <React.Fragment>
      <SEO />

      <Nav />

      <LandingPage />

      {/* <!-- HLAVNY OBSAH --> */}
      <div id="mainSectionContainer">
        <Uvod />

        <Vybavenie />

        <Cennik />

        <Galeria />

        <Kontakt />
      </div>

      {/* <!-- MAPA GOOGLE --> */}
      <GoogleMap />

      <Footer />
    </React.Fragment>
  )
}
